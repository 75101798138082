import { mapState, mapGetters, mapActions } from "vuex";
 import XLSX from "xlsx";
//import XLSX from "xlsx-color";
import api from "../store/apiUtil.js";
import axios from "axios";

export const mixin = {
  data() {
    return {
      default_tab: [
        {
          id: "home",
          title: "화면목록",
          default: "Y",
          icon: "svg-tab-home",
          link: "/home",
        }
      ],
      table_id: "",
      file_name: "",
      sheet_name: "",
      excel_template_headers: {},
      excel_template_bodys: {},
      excel_template_sheet_header: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "AA",
        "AB",
        "AC",
        "AD",
        "AE",
        "AF",
        "AG",
        "AH",
        "AI",
        "AJ",
        "AK",
        "AL",
        "AM",
        "AN",
        "AO",
        "AP",
        "AQ",
        "AR",
        "AS",
        "AT",
        "AU",
        "AV",
        "AW",
        "AX",
        "AY",
        "AZ",
      ],

      /**
       * 챗관련 추가 Start_Arthur.Kim
       */
      talkStatObjs : [  
          {TALK_STAT_CD:'11',TALK_STAT_NM:'대기중',TALK_STAT_NM_TITLE:'대기중',CALSS:'bg-green'}  
        , {TALK_STAT_CD:'13',TALK_STAT_NM:'호전환',TALK_STAT_NM_TITLE:'호전환',CALSS:'bg-cyan'}
        , {TALK_STAT_CD:'14',TALK_STAT_NM:'전달완료',TALK_STAT_NM_TITLE:'전달완료',CALSS:'bg-blueGrey'}
        , {TALK_STAT_CD:'12',TALK_STAT_NM:'상담중',TALK_STAT_NM_TITLE:'상담중',CALSS:'bg-blue'}
        , {TALK_STAT_CD:'20',TALK_STAT_NM:'후처리',TALK_STAT_NM_TITLE:'후처리',CALSS:'bg-blueGrey'}
        , {TALK_STAT_CD:'22',TALK_STAT_NM:'후처리',TALK_STAT_NM_TITLE:'후처리',CALSS:'bg-blueGrey'}
        , {TALK_STAT_CD:'12',TALK_STAT_NM:'',TALK_STAT_NM_TITLE:'중지',SYSMSG_SKIP_YN:'Y',CALSS:'tt-icon-pause'}
        , {TALK_STAT_CD:'21',TALK_STAT_NM:'상담완료',TALK_STAT_NM_TITLE:'상담완료'}
        , {TALK_STAT_CD:'12',TALK_STAT_NM:'콜백',TALK_STAT_NM_TITLE:'콜백',EXEC_RST_CD:'4'}   
        , {TALK_STAT_CD:'20',TALK_STAT_NM:'후처리',TALK_STAT_NM_TITLE:'후처리',EXEC_RST_CD:'4'}
      ],

      channels : [],  // 채널 ( 카카오/티톡 ) 

      /** Arthur.Kim_20220924 */
      alarmTalk_token : {
        access_token : "",          // 토큰 
        expires_in : ""             // 유효시간 
      },

      time: 0,
    };
  },
  mounted() {},
  destroy() {},
  computed: {
    ...mapGetters({
      progress_bar: "commonStore/GE_COMMON_PROGRESS_BAR",
      user_role: "userStore/GE_USER_ROLE",
      user_id: "userStore/GE_USER_ID",
      access_token: "authStore/GE_ACCESS_TOKEN",
      expires_in: "authStore/GE_EXPIRES_IN",
      ge_user_menu: "userStore/GE_USER_MENU",
      user_conn_time: "userStore/GE_CONN_TIME",
      tabs: "commonStore/GE_COMMON_TAB",
      ge_active_tab_title: "commonStore/GE_COMMON_ACTIVE_TAB_TITLE",
      ge_active_tab_num: "commonStore/GE_COMMON_ACTIVE_TAB_NUM",
      ge_active_tab_id: "commonStore/GE_COMMON_ACTIVE_TAB_ID",
      no_cash_tab: "commonStore/GE_COMMON_NO_CASH_TAB",
      progress_bar: "commonStore/GE_COMMON_PROGRESS_BAR",
      geImgExtArry: "commonStore/GE_COMMON_IMAGE_EXT_ARR",
    }),
  },
  methods: {
    
    // 챗팅 상담상태명_Arthur.Kim 
    mixin_get_talk_statnm (talk_stat_cd, resultCd, sysMsgSkipYn)   {
      
      //console.log("talk_stat_cd ::: " + talk_stat_cd);
      //console.log("resultCd ::: " + resultCd);
      //console.log("sysMsgSkipYn ::: " + sysMsgSkipYn);
      
      let obj = _.find(this.talkStatObjs, {'TALK_STAT_CD': talk_stat_cd});
      
      if( talk_stat_cd == '12' && sysMsgSkipYn == 'Y' ) {
        obj = _.find(this.talkStatObjs, { 'TALK_STAT_CD': talk_stat_cd, 'SYSMSG_SKIP_YN':sysMsgSkipYn });
      } else if( resultCd != null && resultCd == '4' ) {
        obj = _.find(this.talkStatObjs, { 'TALK_STAT_CD': talk_stat_cd, 'EXEC_RST_CD': resultCd });
      }
      
      return obj != null ? obj.TALK_STAT_NM : '미등록상태';
    },

    mixin_set_active_tab(item) {

      //설문 전용 조건
      let qutnId='';
      let camBool=false;
      if(!this.mixin_isEmpty(item.QUTN_ID) && (!this.mixin_isEmpty(item.CAM_BOOL) && item.CAM_BOOL)) {
        qutnId=item.QUTN_ID;
        camBool=true;
      }

      if (item.C_NODE_NO) {
        if(!this.mixin_isEmpty(item.VIEW_TRGT) && item.VIEW_TRGT.includes('POP')) {
          let url = `${item.C_NODE_NO}`;
          this.fnWindowOpen(url, item);
        } else {
          this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
            id: item.C_NODE_NO,
            title: item.NODE_TITLE,
            default: "N",
            tabIcon: "",
            link: "/" + item.C_NODE_NO,
            qutnId: qutnId,
            camBool: camBool
          });
        }
      }
    },

    fnWindowOpen(url, item) {
      let widthSize = '1500';
      let heighSize = '800';
      let left = (screen.width - widthSize) / 2;
      let top = (screen.height - heighSize) / 4;

      if(!this.mixin_isEmpty(item.NODE_ETC)) {
        let sizes = item.NODE_ETC.split('|');

        if(sizes.length > 0) {
          widthSize = this.mixin_isEmpty(sizes[0]) ? widthSize : sizes[0];
        }
        if(sizes.length > 1) {
          heighSize = this.mixin_isEmpty(sizes[1]) ? heighSize : sizes[1];
        }
      }

      window.open(url, item.C_NODE_NO, "toolbar=yes,scrollbars=yes,resizable=yes,width="+widthSize+",height="+heighSize+"top="+top+",left="+left);
    },

    mixin_common_Excel(excelHeader,excel1Data,title,sheetName) {
      if(this.$store.getters["userStore/GE_USER_ROLE"].userGroupCd === "COUNSELOR"){
        this.common_alert("엑셀다운로드 권한이 없습니다.", 'error');
        return;
      }
      if(!this.mixin_isEmpty(excel1Data)){
        let dataArray = new Array();
        
        excel1Data.map(e =>{
          let temp = new Map();

          excelHeader.map(cell =>{
            temp.set(String(cell.text + " "), e[cell.value]);
          });
          dataArray.push(Object.fromEntries(temp));
        });
        
        let excelData = XLSX.utils.json_to_sheet(dataArray);
        var workBook = XLSX.utils.book_new(); // 새 시트 생성

        // 시트이름 설정
        // 시트이름을 변경하고 싶은 경우 sheet_name 변수를 변경 하세요
        XLSX.utils.book_append_sheet(workBook, excelData, sheetName);
        if (this.sheet_name !== "") {
          XLSX.utils.book_append_sheet(workBook, excelData, this.sheet_name);
        }
        XLSX.writeFile(workBook, title + ".xlsx"); // 엑셀파일 만듬
  
        //엑셀 내보내기 완료 후 사용된 시트명이 있다면 초기화 한다
        if (this.sheet_name !== "") {
          this.sheet_name = "";
        }

      }else{
        this.common_alert("다운로드할 데이터가 없습니다.", 'chk');
      }
    },
    mixin_common_exportExcel() {
      var excelData = XLSX.utils.table_to_sheet(
        document.getElementById(this.table_id) //table id를 셋팅
      ); // table id를 넣어주면된다
      var workBook = XLSX.utils.book_new(); // 새 시트 생성

      // 시트이름 설정
      // 시트이름을 변경하고 싶은 경우 sheet_name 변수를 변경 하세요
      XLSX.utils.book_append_sheet(workBook, excelData, "");
      if (this.sheet_name !== "") {
        XLSX.utils.book_append_sheet(workBook, excelData, this.sheet_name);
      }
      XLSX.writeFile(workBook, this.file_name + ".xlsx"); // 엑셀파일 만듬

      //엑셀 내보내기 완료 후 사용된 시트명이 있다면 초기화 한다
      if (this.sheet_name !== "") {
        this.sheet_name = "";
      }
    },
    mixin_common_exportExcel_template(
      file_name,
      sheet_name,
      sheet_header,
      sheet_data
    ) {
      //옵션 사용법은 https://www.npmjs.com/package/xlsx-color 참고

      var spreadsheet = XLSX.utils.book_new();
      this.excel_template_headers = sheet_header;
      var keys = Object.keys(sheet_header); //키를 가져옵니다. 이때, keys 는 반복가능한 객체가 됩니다.
      let title = [];
      for (var a = 0; a < keys.length; a++) {
        title.push(this.excel_template_headers[keys[a]]);
      }

      sheet_data.unshift(title); //타이틀 추가
      this.excel_template_bodys[sheet_name] = sheet_data;

      for (var sheet of Object.keys(this.excel_template_bodys)) {
        XLSX.utils.book_append_sheet(
          spreadsheet,
          XLSX.utils.aoa_to_sheet(this.excel_template_bodys[sheet_name]),
          sheet
        );
      }

      for (
        let i = 1;
        i <= this.excel_template_bodys[sheet_name][0].length;
        i++
      ) {
        var sheet_title = this.excel_template_sheet_header[i - 1] + "1";

        //첫번째 가로줄를 배경색 처리
        spreadsheet.Sheets[sheet_name][sheet_title].s = {
          fill: {
            patternType: "solid",
            fgColor: { rgb: "FFFF00" },
          },
          font: {
            bold: true,
            sz: "20px", 

          },
        };
      }

      XLSX.writeFile(spreadsheet, file_name + ".xlsx");
    },
    mixin_dateFormat(phoneNumber){
      if (!phoneNumber) return phoneNumber;

      //공백제거
      phoneNumber = phoneNumber.replace(/\s/gi, "");
      phoneNumber = phoneNumber.replaceAll("-", "")
      
      return phoneNumber;
  
    },
    async common_getAspDataS(userId) {
      let requestData = {
        headers: {},
        sendData: {},
      };
      // header 세팅
      // requestData.headers["URL"] = "api/code/common/code-book/aspinqry";
      requestData.headers["SERVICE"] = "code.common.code-book.sender";
      requestData.headers["METHOD"] = "aspinqry";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["ASP_USER_ID"] = userId;
      let response = await this.common_postCall(requestData, false);
      return response;
    },

    //사용자에 따라 건설사 코드, 사용자 코드 조회
    //userId(사용자ID), type(건설사코드/사업지코드), ctcp_cd(사업지코드)
    async mixin_buss_ctcp_code_user_auto(userid) {
      var responseData = [];
      await api
        .post(
          "api/code/common/code-book/userauth",
          {
            USER_ID: userid,
          },
          {
            head: {
              SERVICE: "code.common.code-book.userauth",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
        
          responseData = response.data["DATA"];
         // console.log(responseData);
      
        })

        .catch((err) => {
          alert(err);
        });

      return responseData;
    },


    //캠페인코드, 명 코드셋을 API로 호출
    async mixin_cmpcode_get(buss_cd) {
        var responseData = [];
        await api
          .post(
            "/api/hlw/campaign/manage/combo/list",
            {
              BUSS_CODE: buss_cd
  
            },
            {
              head: {
                SERVICE: "hlw.campaign.manage.combo",
                METHOD: "inqry",
                TYPE: "BIZ_SERVICE",
              },
            }
          )
          .then((response) => {
            if (response.data) {
              responseData = response.data["DATA"];
              
            }
          })
          .catch((error) => {});
        
        return responseData;
      },
      async mixin_alarmTalk_kakatalk( requestSendMessage ) {


  //  url: "http://localhost:7443/skill/adminAlarmTalk",
            

          //  url: process.env.VUE_APP_KKO_SKILL_BACKEND_URL + "/skill/adminAlarmTalk",
          //url: "http://admin.opentohome.com:7443/skill/adminAlarmTalk",
          $.ajax({
            url: process.env.VUE_APP_KKO_SKILL_BACKEND_URL + "/skill/adminAlarmTalk",
            
    
                dataType: "json",
                type: "POST",
                ContentType: "text/plain",
                crossDomain: true,
    
                data: requestSendMessage,
                  success: function (data) {
                  //  Callbar(data);
                  },
                  error: function (request, status, error) {
                
                  },
                  
              })
    
    
        },
      //  
      async mixin_alarmTalkLog_get( requestHis ) {
          console.log(requestHis);
          api
            .post(
              "/api/hlw/campaign/alarmTalk-hismsg/manage/list",
              requestHis,
              {
                head: {
                  SERVICE: "hlw.campaign.alarmTalk-hismsg.manage.combo",
                  METHOD: "inqry",
                  TYPE: "BIZ_SERVICE",
                },
              }
            )
            .then((response) => {
              if (response.data) {
                console.log("알림톡 전송 로그 저장 성공")
              }
            })
            .catch((error) => {
              console.log("알림톡 전송 로그 저장 실패")
            });
    
    
      },

      /**
       * Arthur.Kim_20220924 알림톡 토큰 생성 
       * @param {*} requestSendMessage 
       * @param {*} requestHis 
       */  
      async mixin_alarmTalk_token() {
        
        let responseData = [];
        let data = {
          access_token:"",
          expires_in:""
        };

        await api
        .post(
          "/api/setting/system/cmmn-code-manage/alarm-token/inqire",    // api/code/common/code-book/inqry
          {},
          {
            head: {
              SERVICE: "setting.system.cmmn-code-manage.alarm-token",
              METHOD: "inqry",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {

          if (response.data) {

            responseData = response.data["DATA"];

            data.access_token = responseData[0].access_token;
            data.expires_in = responseData[0].expires_in;

            this.$store.dispatch("authStore/AC_ALARM_TALK", data);
          }

        })
        .catch((error) => {});
        //console.log("#### [mixins][common_code] responseData=", responseData);
        //return responseData;
      },

      // async mixin_alarmTalk_get( requestSendMessage,requestHis ) {
  
      //   let  KAKAO_ENTER_KEY = "Basic R000000004 R000000004_NevKR4A/SzSnO8NSjfzOmg";
      //   let  KAKAO_ENTER_VERSION = "v1";
      //   let  KAKAO_ENTER_SEND_MESSAGE = "https://bizmsg-web.kakaoenterprise.com/v1/message/send";
    
      //   const headers = {
      //     headers: {
      //       "Authorization":KAKAO_ENTER_KEY,
      //       "version":KAKAO_ENTER_VERSION,
      //       "content-type":"application/x-www-form-urlencoded",
      //     }
      //   }
      //   let data = {};
      //   axios.post("/ent/v1/oauth/token", data, headers).then(res=>{
    
    
    
      //     console.log("성공", res.data.access_token)
      //     const sendHeader = {
      //       headers: {
      //         "accept": "*/*",
      //         "Authorization": 'Bearer '+ res.data.access_token,
      //         "content-type": "application/json"
      //       }
      //     }
    
    
      //   axios.post("https://bizmsg-web.kakaoenterprise.com/v1/message/send", requestSendMessage, sendHeader).then(res=>{
          
      //     if(res.data.message === '실패'){
      //       console.log(res.data);
      //     }
      
      //     requestHis.MSG_TYPE = res.data.message; 
      //     console.log(requestHis);
      //     api
      //       .post(
      //         "/api/hlw/campaign/alarmTalk-hismsg/manage/list",
      //         requestHis,
      //         {
      //           head: {
      //             SERVICE: "hlw.campaign.alarmTalk-hismsg.manage.combo",
      //             METHOD: "inqry",
      //             TYPE: "BIZ_SERVICE",
      //           },
      //         }
      //       )
      //       .then((response) => {
      //         if (response.data) {
      //           console.log("알림톡 전송 로그 저장 성공")
                
      //         }
      //       })
      //       .catch((error) => {
      //         console.log("알림톡 전송 로그 저장 실패")
      //       });
    
          
      //   }).catch(err=>{
      //     console.log(err.response.data);
      //   })
      // }).catch(err=>{
      //   console.log(err);
      // })
    
    
      //   },
     
    // //알림톡 관련 (사전방문예약자 정보 가져오기)
    // async mixin_alarmTalk_custInfo_post(dateSet, dateSet2) {
    //   var responseData = [];
    //   await api
    //     .post(
    //       "/api/hlw/campaign/alarmTalk-custinfo/manage/list",
    //       {
    //         BUSS_CODE: dateSet

    //       },
    //       {
    //         head: {
    //           SERVICE: "hlw.campaign.manage.combo",
    //           METHOD: "inqry",
    //           TYPE: "BIZ_SERVICE",
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       if (response.data) {
    //         responseData = response.data["DATA"];
            
    //       }
    //     })
    //     .catch((error) => {});
      
    //   return responseData;
    // },

    //사업지코드, 명 코드셋을 API로 호출
    async mixin_busscode_get(ctcp_cd, userId) {
      var responseData = [];

      await api
        .post(
          "api/code/common/code-book/bussCd",
          {
            CTCP_CD: ctcp_cd,
            USER_ID: userId,
          },
          {
            head: {
              SERVICE: "code.common.code-book.bussCd",
              METHOD: "inqry",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          if (response.data) {
            responseData = response.data["DATA"];
          }
        })
        .catch((error) => {});

      return responseData;
    },

    async mixin_mi_busscode_get(ctcp_cd, userId) {
      var responseData = [];
      await api
        .post(
          "api/code/common/code-book/bussCd",
          {
            CTCP_CD: ctcp_cd,
            USER_ID: userId,
            PART_TYPE: 'MI'
          },
          {
            head: {
              SERVICE: "code.common.code-book.bussCd",
              METHOD: "inqry",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          if (response.data) {
            responseData = response.data["DATA"];
          }
        })
        .catch((error) => {});

      return responseData;
    },

    //페이지에서 사용하는 코드셋을 API로 호출
    async mixin_common_code_get_all(group_cd) {
      var responseData = [];
      await api
        .post(
          "api/code/common/code-book/inqry",
          {
            GROUP_CD: group_cd
          },
          {
            head: {
              SERVICE: "code.common.code-book.inqry",
              METHOD: "inqry",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          if (response.data) {
            for (var i = 0; i < group_cd.length; i++) {
              if (response.data[group_cd[i]]) {
                // if (response.data[key] !== undefined) {
                var key = group_cd[i];
                var jasonData = {};
                jasonData["group_cd"] = key;
                jasonData["group_value"] = response.data[key];
                responseData.push(jasonData);
                // }
              }
            }
          }
        })
        .catch((error) => {});
       //console.log("#### [mixins][common_code] responseData=", responseData);
      return responseData;

    },

    //페이지에서 호출되어 있는 코드셋에서 하나의 그룹코드를 필터하여 가져온다
    mixin_common_code_get(all_code, group_cd, strHeadText) {
      var responseData = [];

      if (strHeadText != "" && strHeadText != undefined) {
        responseData.push({ CD_NM: strHeadText, CD: "" });
      }

      if (all_code && typeof all_code === "object") {
        for (var i = 0; i < all_code.length; i++) {
          if (all_code[i].group_cd === group_cd) {
            for (var a = 0; a < all_code[i].group_value.length; a++) {
              responseData.push({
                CD_NM: all_code[i].group_value[a].CD_NM,
                CD: all_code[i].group_value[a].CD,
                info1: all_code[i].group_value[a].ETC_INFO01,
                info2: all_code[i].group_value[a].ETC_INFO02,
                info3: all_code[i].group_value[a].ETC_INFO03,
              });
            }
          }
        }
      }
      return responseData;
    },

    mixin_getAspAuth() {
      api
        .post(
          "api/palette-main/myAuth/inqire", //api url입력
          {
            USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          },
          {
            head: {
              SERVICE: "api.palette-main.auth",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          return response.data.DATA;
        })
        .catch((err) => {
          alert(err);
        });
    },
    //페이지별 버튼권한 조회
    async mixin_common_button_auth(menu_id) {
      var responseData = [];
      await api
        .post(
          "api/setting/system/menu-author-manage/menu-button-author/inqire",
          {
            USER_ID: this.$store.getters["userStore/GE_USER_ID"],
            MENU_ID: menu_id,
            GROUP_CD: "TWB007", //고정코드(공통코드)
          }
        )
        .then((response) => {
          //console.log(
            //"#### [mixins][mixin_common_button_auth] response=",
            //response
          //);
        })
        .catch((error) => {});
      // console.log("#### [mixins][common_code] responseData=", responseData);
      return responseData;
    },
    //문의유형 코드 조회
    async mixin_inqry_code_get(asp_newcust_key, spst_inqry_typ_cd) {
      var responseData = [];
      await api
        .post("api/chat/setting/inqry-ty-manage/list", {
          ASP_NEWCUST_KEY: asp_newcust_key,
          SPST_INQRY_TYP_CD: spst_inqry_typ_cd,
          USE_YN: "Y", //고정코드
        })
        .then((response) => {
          if (response.data) {
            responseData = response.data["DATA"];
          }
        })
        .catch((error) => {});
      // console.log("#### [mixins][common_code] responseData=", responseData);
      return responseData;
    },
    //상담유형 코드 조회
    async mixin_counsel_type_code_get(
      asp_newcust_key,
      spst_cnsl_typ_cd,
      strHeadText
    ) {
      var responseData = [];
      await api
        .post("api/setting/consulttype/manage/inqire", {
          ASP_NEWCUST_KEY: asp_newcust_key,
          SPST_CNSL_TYP_CD: spst_cnsl_typ_cd,
          USE_YN: "Y", //고정코드
        })
        .then((response) => {
          if (response.data) {
            responseData = response.data["DATA"];

            if (strHeadText != "" && strHeadText != undefined) {
              responseData.unshift({ CD_NM: strHeadText, CD: "" });
            }
          }
        })
        .catch((error) => {});
      // console.log("#### [mixins][common_code] responseData=", responseData);
      return responseData;
    },
    //사용자소속 코드 조회
    async mixin_attr_get(asp_newcust_key, attr_cd, default_text) {
      var responseData = [];
      await api
        .post("api/palette/common/get-attr-div/list", {
          ASP_NEWCUST_KEY: asp_newcust_key,
          ATTR_CD: attr_cd,
          ATTR_DIV_CD: "", //고정코드
        })
        .then((response) => {
          if (response.data) {
            responseData = response.data["DATA"];
            if (
              default_text != null &&
              default_text != "" &&
              default_text != undefined
            ) {
              let default_obj = {
                CD_NM: default_text,
                CD: "",
              };
              responseData.splice(0, 0, default_obj);
            }
          }
        })
        .catch((error) => {});
      // console.log("#### [mixins][common_code] responseData=", responseData);
      return responseData;
    },
    //API 파라미터 에러 체크
    mixin_check_repsonse(response) {
      let return_data = true;
      if (response.error.flag && response.error.flag) {
        return_data = false;
        this.common_toast(response.error.msg, "error");
      }
      return return_data;
    },
    //채팅채널 조회
    async mixin_channel_get(asp_cust_key) {
      var responseData = [];
      await api
        .post(
          "api/chat/main/sender-key/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: asp_cust_key,
          },
          {
            head: {
              SERVICE: "code.common.code-book",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          // console.log(response.data.DATA);
          responseData = response.data["DATA"];
        })
        .catch((err) => {
          alert(err);
        });

      return responseData;
    },
    //모든 기업 계정관리의 ASP_CUST를 가져와 시스템 관리자의 콤보 박스로 사용
    async mixin_getAllAspData() {
      // console.log("#### [mixin_getAllAspData]");
      var responseData = [];
      await api
        .post(
          "/api/code/common/code-book/allaspinqry", //api url입력
          {},
          {
            head: {
              SERVICE: "code.common.code-book",
              METHOD: "allaspinqry",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          // console.log(response.data.DATA);
          responseData = response.data["DATA"];
        })
        .catch((err) => {
          alert(err);
        });

      return responseData;
    },
    //사용자 권한 콤보박스 생성
    async mixin_user_auth_get(default_text) {
      var responseData = [];
      await api
        .post(
          "api/palette-main/auth/inqire", //api url입력
          {
            userId: this.user_id,
          },
          {
            head: {
              SERVICE: "api.palette-main.auth",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          // console.log(response.data.DATA);
          responseData = response.data["DATA"];
          if (
            default_text != null &&
            default_text != "" &&
            default_text != undefined
          ) {
            let default_obj = {
              NM: default_text,
              CD: "",
            };
            responseData.splice(0, 0, default_obj);
          }
        })
        .catch((err) => {
          alert(err);
        });

      return responseData;
    },
    /**
     * 공통 toast
     * toast메시지와 타입을 전달하여 사용한다.
     * 예시) this.common_toast("정상적으로 처리되었습니다.","sucess");
     * @param message : toast메시지
     * @param iconType : success,indo,warn,error,''(default)
     */
    common_toast(message, type) {
      let set_class = "";
      let set_icon = "";
      switch (type) {
        case "success":
          set_class = "";
          set_icon = "svg-sucess";
          break;
        case "info":
          set_class = "toast-info";
          set_icon = "svg-info";
          break;
        case "warn":
          set_class = "toast-warning";
          set_icon = "svg-warning";
          break;
        case "error":
          set_class = "toast-error";
          set_icon = "svg-error";
          break;
        default:
          set_class = "";
          set_icon = "";
          break;
      }
      this.$store.commit("toastStore/POP", {
        act: true,
        msg: message,
        class: set_class,
        hasToastIcon: false,
        icon: set_icon,
      });
    },
    /**
     * 공통 alert
     * 출력메시지와 아이콘 타입을 전달하여 사용한다.
     * 예시) this.common_alert("확인?"); //iconType 생략시 done으로 사용
     * @param message : 출력메시지
     * @param iconType : done,noti,error,chk
     */
    common_alert(message, iconType) {
      let iconClass = "";
      switch (iconType) {
        case "done":
          iconClass = "svg-done-lg";
          break;
        case "noti":
          iconClass = "svg-noti-lg";
          break;
        case "error":
          iconClass = "svg-error-lg";
          break;
        case "chk":
          iconClass = "svg-chk-lg";
          break;
        default:
          iconClass = "svg-done-lg";
          break;
      }
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: message,
        iconClass: iconClass,
        type: "default",
      });
    },
    /**
     * 공통 confirm
     * 출력메시지와 아이콘 타입을 전달하여 사용한다.
     * callYes, callNo 함수를 별도로 구현하여 callBack형태로 넘겨준다.
     * 예시) this.common_confirm(msg, this.saveCardYesCall, num, null, null, "error");
     * @param message : string
     * @param callYes : function
     * @param callYesArgs : any
     * @param callNo : function
     * @param callNoArgs : any
     * @param iconType : done,noti,error,chk
     */
    common_confirm(
      message,
      callYes,
      callYesArgs,
      callNo,
      callNoArgs,
      iconType
    ) {
      let iconClass = "";
      switch (iconType) {
        case "done":
          iconClass = "svg-done-lg";
          break;
        case "noti":
          iconClass = "svg-noti-lg";
          break;
        case "error":
          iconClass = "svg-error-lg";
          break;
        case "chk":
          iconClass = "svg-chk-lg";
          break;
        default:
          iconClass = "svg-chk-lg";
          break;
      }

      const parentVue = this;
      const parentCallYes = callYes;
      const parentCallYesArgs = callYesArgs;
      const parentCallNo = callNo;
      const parentCallNoArgs = callNoArgs;
      //console.log("##### [common_confirm] parentCallYes=", parentCallYes);
      //console.log(
      //  "##### [common_confirm] parentCallYesArgs=",
      //  parentCallYesArgs
      //);
      //console.log("##### [common_confirm] parentCallNo=", parentCallNo);
      //console.log("##### [common_confirm] parentCallNoArgs=", parentCallNoArgs);
      //console.log("##### [common_confirm] message=", message);
      //console.log("##### [common_confirm] iconClass=", iconClass);

      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: message,
        iconClass: iconClass,
        type: "confirm",
        callYes: function () {
          parentVue.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: false,
          });
          if (typeof parentCallYes == "function") {
            parentCallYes(parentCallYesArgs);
          }
        },
        callNo: function () {
          parentVue.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: false,
          });
          if (typeof parentCallNo == "function") {
            parentCallNo(parentCallNoArgs);
          }
        },
      });
    },

    /**
     * 공통 postCall
     * requestData를 선언하여 헤더와 sendData를 세팅 후 전달하면 결과를 리턴해준다.
     * 함수 선언시 async 사용.
     * ex)
     * async getList(){
     *     requestData = {
     *             headers: {},
     *             sendData:{},
     *           };
     *     // header 세팅
     *     requestData.headers["URL"] = "/api/chat/setting/message-manage/rcept-delay/inqire";
     *     requestData.headers["SERVICE"] = "chat.setting.message-manage.rcept-delay";
     *     requestData.headers["METHOD"] = "inqire";
     *     requestData.headers["TYPE"] = "BIZ_SERVICE";
     *     requestData.headers["ASYNC"] = false;
     *
     *     // sendData 세팅
     *     requestData.sendData["MSG_CL"] = this.aspNewcustKey;
     *     requestData.sendData["SYSTEM_MSG_ID"] = this.aspNewcustKey;
     *     requestData.sendData["ASP_NEWCUST_KEY"] = this.aspNewcustKey;
     *
     *     // 결과 리턴
     *     let response = await this.common_postCall(requestData, false);
     *     console.log(response);
     * }
     *
     * @param requestData
     * @returns {Promise<{}>}
     */
    async common_postCall(requestData, isLoadingbar) {
      if (
        typeof isLoadingbar === "undefined" ||
        typeof isLoadingbar !== "boolean"
      ) {
        isLoadingbar = true;
      }
      if (isLoadingbar) {
        await this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", true);
      }
      let responseData = {};
      let requestUrl = requestData.headers.URL;
      // HEADER[URL] 중복 제거
      delete requestData.headers.URL;

      if(!this.mixin_isEmpty(requestData.sendData.ASP_CUST_KEY)) {
        delete requestData.sendData.ASP_CUST_KEY;
      }

      requestData.sendData['ASP_CUST_KEY'] = 'HLW';

      await api
        .post(
          requestUrl, //api url입력
          requestData.sendData,
          { head: requestData.headers }
        ) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
        .then((response) => {
          if (isLoadingbar) {
            this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", false);
          }
          // 공통 에러 체크로 변경
          responseData = response.data;
        }) //api 호출 성공 이후 수행
        .catch((err) => {
          if (isLoadingbar) {
            this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", false);
          }
          this.common_alert(err);
        }); //api호출 에러 작업 수행
      return responseData;
      //결과값 보여주기
    },

    // 업무에 따른 고유키정보를 가져온다.
    // DB 내부에서 채번된 시퀀스값(YYYYMMDDHHMISSMSC + 업무구분(대문자4자리) + 00000)
    async mixin_common_bizSeq_get(BIZ_CASE) {
      var responseData = "";
      await api
        .post("/api/innb/common/generate-unique-no/inqry", {
          BIZ_CASE: BIZ_CASE,
        })
        .then((response) => {
          //console.log(
          //    "#### [mixins][mixin_common_bizSeq_get] response=",
          //    response
          //);
          responseData = response.data.DATA[0]["RET_VAL"];
        })
        .catch((error) => {});
      return responseData;
    },
    //LMS 컨텐츠분류코드 조회
    async mixin_getDivCodeData(strCode) {
      let responseData = [];

      let requestData = {
        headers: {},
        sendData: {},
      };
      // header 세팅
      requestData.headers["URL"] = "api/km/category/manage/div-cd/list";
      requestData.headers["SERVICE"] = "km.category.manage.div-cd";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["SPST_DIV_ID"] = strCode;

      // 결과 리턴
      let response = await this.common_postCall(requestData, false);

      if (!response.HEADER.ERROR_FLAG) {
        if (response[strCode]) {
          var key = strCode;
          var jasonData = {};
          jasonData["group_cd"] = key;
          jasonData["group_value"] = response[key];
          responseData.push(jasonData);
        }
      }

      return responseData;
    },

    restoreXSS(strText) {
      //값존재유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }
      strText = strText.toString();

      //문자열 길이가 4이상일 경우만 처리
      if (strText.length <= 3) {
        return strText;
      }

      //XSS이 적용되었을 경우 원래 문자로 치환하여 반환한다.
      // strText = strText.replaceAll("&amp;", "&");
      //      strText = strText.replaceAll("&lt;","&#60;");
      //      strText = strText.replaceAll("&gt;","&#62;");
      //      strText = strText.replaceAll("&lt;","<");
      //      strText = strText.replaceAll("&gt;",">");
      // strText = strText.replaceAll("&lt;", "&lt;");
      // strText = strText.replaceAll("&gt;", "&gt;");
      //트리에서 스크립트 사용시 문제되서 주석처리함
      // strText = strText.replaceAll("<","&lt;");
      // strText = strText.replaceAll(">","&gt;");
      // strText = strText.replaceAll("&#40;", "(");
      // strText = strText.replaceAll("&#41;", ")");
      // strText = strText.replaceAll("&apos;", "'");
      // strText = strText.replaceAll("&#91;", "[");
      // strText = strText.replaceAll("&#93;", "]");
      // strText = strText.replaceAll("&quot;", '"');

      strText = strText.replaceAll("&lt;", "<");
      strText = strText.replaceAll("&gt;", ">");
      strText = strText.replaceAll("&#40;", "(");
      strText = strText.replaceAll("&#41;", ")");
      strText = strText.replaceAll("&#91;", "[");
      strText = strText.replaceAll("&#93;", "]");
      strText = strText.replaceAll("&amp;", "&");
      strText = strText.replaceAll("&quot;", '"');
      strText = strText.replaceAll("&apos;", "'");
      strText = strText.replaceAll("<br>", "\r");
      strText = strText.replaceAll("<p>", "\n");

      return strText;
    },
    restoreXSS_CKeditorDec(strText) {
      //값존재유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }
      strText = strText.toString();

      //XSS이 적용되었을 경우 원래 문자로 치환하여 반환한다.
      strText = strText.replaceAll(/\n/g, "<br>");
      strText = strText.replaceAll(/\r\n/g, "<br>");
      strText = strText.replaceAll("<", "&lt;");
      strText = strText.replaceAll(">", "&gt;");

      return strText;
    },
    restoreXSS_CKeditor(strText) {
      //값존재유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }
      strText = strText.toString();

      //XSS이 적용되었을 경우 원래 문자로 치환하여 반환한다.
      strText = strText.replaceAll("&lt;", "<");
      strText = strText.replaceAll("&gt;", ">");
      strText = strText.replaceAll("<br>", "\n");

      return strText;
    },
    restoreXSS_Dec(strText) {
      //값존재유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }
      strText = strText.toString();

      //문자열 길이가 4이상일 경우만 처리
      // 이부분이 왜 걸려잇는지 모르겠음...테스트 후 길이체크는 막을 예정..
      // if(strText.length <= 3){return strText;}

      //XSS이 적용되었을 경우 원래 문자로 치환하여 반환한다.
      strText = strText.replaceAll("(", "&#40;");
      strText = strText.replaceAll(")", "&#41;");
      strText = strText.replaceAll("[", "&#91;");
      strText = strText.replaceAll("]", "&#93;");
      strText = strText.replaceAll("&", "&amp;");
      strText = strText.replaceAll('"', "&quot;");
      strText = strText.replaceAll("'", "&apos;");
      strText = strText.replaceAll("\r", "<br>");
      strText = strText.replaceAll("\n", "<p>");
      strText = strText.replaceAll("<", "&lt;");
      strText = strText.replaceAll(">", "&gt;");
      return strText;
    },

    //data-table 숫자 합계구하기
    //data_list : 데이터 리스트(JSON ARRAY)
    //key: 데이터 리스트의(sum을 구할 data_list에 포함된 key값명)
    mixin_sum_field(data_list, key) {
      //소수점 더하기일때 이상한 값을때를 위해 패치(toFixed)
      let sum = data_list.reduce(
        (a, b) => +(a + Number(b[key]) || 0).toFixed(12),
        0
      );
      return sum;
    },
    
    //data-table time 합계구하기
    //data_list : 데이터 리스트(JSON ARRAY)
    //key: 데이터 리스트의(sum을 구할 data_list에 포함된 key값명)
    //string(HH:MM:ss) 데이터를 초로 변환 합계 후 다시 string(HH:MM:ss) 값으로 변환
    mixin_date_sum_field(data_list, key) {
      let sum = 0;
      for (let i = 0; i < data_list.length; i++) {
        if (data_list[i][key] !== "0") {
          sum =
            sum +
            data_list[i][key]
              .split(":")
              .reverse()
              .reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0);
        }
      }

      let return_data = "00:00:00";
      if (sum !== 0) {
        return_data = this.mixin_seconds_toHHMMSS(sum);
      }

      return return_data;
    },
    //평균 건수를 구한다(소수점 한자리)
    mixin_avg_field(data_list, total_key, base_key) {
      //소수점 더하기일때 이상한 값을때를 위해 패치(toFixed)
      let total_sum = data_list.reduce(
        (a, b) => +(a + Number(b[total_key]) || 0).toFixed(12),
        0
      );

      let base_sum = data_list.reduce(
        (a, b) => +(a + Number(b[base_key]) || 0).toFixed(12),
        0
      );

      let avg = 0;
      if (total_sum !== 0 && base_sum !== 0) {
        avg = ((total_sum / base_sum) * 100).toFixed(1);
      }

      return avg;
    },
    //시간 데이터를 가지고 평균 시간을 구한다
    //총시간 / 데이터 건수 (단, 0인건은 데이터 건수에 포함하지 않는다)
    mixin_date_avg_field(data_list, key) {
      let sum = 0;
      let avg = 0;
      let count = 0; //시간이 0인건은 포함시키지 않는다
      for (let i = 0; i < data_list.length; i++) {
        if (data_list[i][key] !== "0" && data_list[i][key] !== "00:00:00") {
          sum =
            sum +
            data_list[i][key]
              .split(":")
              .reverse()
              .reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0);
          count++;
        }
      }

      let return_data = "00:00:00";

      //평균시간을 구한다
      if (sum !== 0 && count !== 0) {
        avg = (sum / count).toFixed(0);
      }

      //HH:MM:SS 표기로 변환 한다
      if (sum !== 0 && count !== 0) {
        return_data = this.mixin_seconds_toHHMMSS(avg);
      }

      return return_data;
    },
    //seconds를 HH:MM:ss로 변환한다
    mixin_seconds_toHHMMSS(secs) {
      var sec_num = parseInt(secs, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor(sec_num / 60) % 60;
      var seconds = sec_num % 60;

      return (
        [hours, minutes, seconds]
          .map((v) => (v < 10 ? "0" + v : v))
          // .filter((v, i) => v !== "00" || i > 0)
          .join(":")
      );
    },
    /**
     * 서버 시간을 포맷에 맞춰 반환한다.
     * ex)
     * let currentDate = await this.mixin_getSvrDate("YYYYMMDD");
     * console.log(currentDate);
     * @param strFormat
     * @returns {Promise<{}|*>}
     */
    async mixin_getSvrDate(strFormat) {
      let requestData = {
        headers: {},
        sendData: {},
      };
      // header 세팅
      requestData.headers["URL"] = "/api/date/common/current-time/inqry";
      requestData.headers["SERVICE"] = "date.common.current-time";
      requestData.headers["METHOD"] = "inqry";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["FORMAT01"] = strFormat;
      let response = await this.common_postCall(requestData, false);
      if (!response.HEADER.ERROR_FLAG) {
        let resultData = response.DATA[0].RET_VAL;
        return resultData;
      } else {
        return response;
      }
    },

    /**
     * 서버 시간을 포맷에 맞춰 오늘날짜, 3일전, 7주일전, 1달전, 3달전 반환한다.
     * ex)
     * let currentDate = await this.mixin_getSvrDate("YYYYMMDD");
     * console.log(currentDate);
     * @param strFormat
     * @returns {Promise<{}|*>}
     */
    async mixin_getSvrPrevDate(strFormat) {
      let requestData = {
        headers: {},
        sendData: {},
      };
      // header 세팅
      requestData.headers["URL"] = "/api/date/common/prev-date/inqry";
      requestData.headers["SERVICE"] = "date.common.prev-date";
      requestData.headers["METHOD"] = "inqry";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["FORMAT01"] = strFormat;
      requestData.sendData["BASE_DATE"] = strFormat;
      let response = await this.common_postCall(requestData, false);
      if (!response.HEADER.ERROR_FLAG) {
        let resultData = response.DATA[0];
        return resultData;
      } else {
        return response;
      }
    },

    /**
	 * 두날짜의 차이를 구분에 따라 계산하여 검색한 후 차이숫자를 반환한다.
	 * @param strDataCase 날짜 구분(YEAR, MONTH, DAY, HOUR, MINUTE)
	 * @param strDateFrom 기준날짜 From
	 * @param strDateTo 기준날짜 To
	 * @return 포맷된 서버 일시
	 * @author MPC R&D Team
	 */
    async mixin_selectDiffDate(strDataCase, strDateFrom, strDateTo) {
      let requestData = {
        headers: {},
        sendData: {},
      };

      // header 세팅
      requestData.headers["URL"] = "/api/date/common/date-dffrnc-calc/inqry";
      requestData.headers["SERVICE"] = "date.common.date-dffrnc-calc";
      requestData.headers["METHOD"] = "inqry";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["DATE_CASE"] = strDataCase;
      requestData.sendData["DATE_FROM"] = strDateFrom;
      requestData.sendData["DATE_TO"] = strDateTo;
      let response = await this.common_postCall(requestData, false);
      
      if (!response.HEADER.ERROR_FLAG) {
        let resultData = response.DATA[0].RET_VAL;
        return resultData;
      } else {
        return response;
      }
    },

    /**
     * 자리수 만큼 문자열 채워준다.
     * @param {String} strVal 베이스 문자열.
     * @param {int} intLen 리턴 문자열 자리수.
     * @param {String} strExtVal 문자열 자리수에 맞춰 채워넣을 문자열.
     * @param {String} strDirection 문자열 채울 방향 'L', 'R'
     * @return {String} strRetVal 반환되는 padding 문자열
     * @author MPC R&D Team
     */
    mixin_setTxtPad(strVal, intLen, strExtVal, strDirection) {
      if (typeof strVal === "undefined") {
        return "";
      }
      if (typeof strVal !== "string") {
        strVal = strVal.toString();
      }
      if (typeof intLen === "undefined") {
        intLen = 0;
      }
      if (typeof strExtVal === "undefined") {
        return strVal;
      }
      if (typeof strDirection === "undefined") {
        strDirection = "L";
      }
      if (strVal.length >= intLen) {
        return strVal;
      }

      var strRetVal = strVal;
      intLen = intLen - strVal.length;
      for (var i = 0; i < intLen; i++) {
        if (strDirection === "L") {
          strRetVal = strExtVal + strRetVal;
        } else {
          strRetVal = strRetVal + strExtVal;
        }
      }

      return strRetVal;
    },
    mixin_rpad: function (strVal, intLen, strExtVal) {
      return this.mixin_setTxtPad(strVal, intLen, strExtVal, "L");
    },
    mixin_lpad: function (str, _length, ch_str) {
      return this.mixin_setTxtPad(strVal, intLen, strExtVal, "R");
    },
    /**
     * 빈값 체크
     * @param {any} val
     * @returns {boolean}
     */
    mixin_isEmpty(val) {
      if (val == null) return true;
      if (typeof val === "undefined") return true;
      if (typeof val === "string" && val === "" && "" + val === "") return true;
      if (Array.isArray(val) && val.length < 1) return true;
      if (
        typeof val === "object" &&
        val.constructor.name === "Object" &&
        Object.keys(val).length < 1
      )
        return true;
      if (
        typeof val === "object" &&
        val.constructor.name === "String" &&
        Object.keys(val).length < 1
      )
        return true;
    },
    /**
     * isEmpty 체크 후 true일 경우 defaultVal 리턴
     * @param {any} val
     * @param {any} defaultVal
     * @returns {any}
     */
    mixin_nvl(val, defaultVal) {
      return this.mixin_isEmpty(val) ? defaultVal : val;
    },
    async requestDownloadApi(requestObj) {
      // _.merge - Lodash 라이브러리
      return axios({
        url: process.env.VUE_APP_API_URL + requestObj.url,
        responseType: "arraybuffer",
        method: "get",
      })
        .then((res) => {
          try {
            let blob = new Blob([res.data], {
              type: res.headers["content-type"],
            });
            // let fileName = this.getFileName(res.headers["content-disposition"]);
            // fileName = decodeURI(fileName); // 파일명 디코딩 (프로젝트에 따라 사용여부 옵션)

            if (window.navigator.msSaveOrOpenBlob) {
              // IE 10+
              window.navigator.msSaveOrOpenBlob(blob);
            } else {
              // not IE
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.target = "_self";
              // if (fileName) link.download = fileName;
              link.click();
            }

            requestObj.callback(res.data);
          } catch (e) {
            console.error(e);
          }
        })
        .catch((res) => {
          // status 200이 아닌경우에도 콜백호출 (프로젝트에 맞게 수정필요)
          requestObj.callback(res.response.data);
        });
    },
    getFileName(contentDisposition) {
      let fileName = contentDisposition
        .split(";")
        .filter((ele) => {
          return ele.indexOf("filename") > -1;
        })
        .map((ele) => {
          return ele.replace(/"/g, "").split("=")[1];
        });
      return fileName[0] ? fileName[0] : null;
    },
    async setFileDownCnt(FILE_GROUP_KEY, FILE_KEY, asp_cust_key) {
      let rtnBln = false;
      let requestObj = {
        headers: {},
        data: {},
      };

      const token = window.sessionStorage.getItem("token");
      // request.headers.token = token;
      //resuest header에 전달받은 accessToken을 Bearer 형식으로 전송
      requestObj.headers.Authorization = "bearer " + token;
      let form = new FormData();
      form.append("fileGroupKey", FILE_GROUP_KEY);
      form.append("fileKey", FILE_KEY);
      form.append("aspCustKey", asp_cust_key);
      form.append(
        "procId",
        this.$store.getters["userStore/GE_USER_ROLE"].userId
      );
      form.append(
        "amdrId",
        this.$store.getters["userStore/GE_USER_ROLE"].userId
      );

      axios({
        url: process.env.VUE_APP_API_URL + "api/file/update-dnlod-cnt",
        headers: requestObj.headers,

        method: "post",
        data: form,
      })
        .then((res) => {
          rtnBln = true;
          return rtnBln;
        })
        .catch((res) => {
          // status 200이 아닌경우에도 콜백호출 (프로젝트에 맞게 수정필요)
          return rtnBln;
        });
    },

    async deleteFile(FILE_GROUP_KEY, FILE_KEY, asp_cust_key) {
      let rtnBln = false;
      let requestObj = {
        headers: {},
        data: {},
      };

      const token = window.sessionStorage.getItem("token");
      // request.headers.token = token;
      //resuest header에 전달받은 accessToken을 Bearer 형식으로 전송
      requestObj.headers.Authorization = "bearer " + token;

      await axios({
        url: process.env.VUE_APP_API_URL + "api/file/delete",
        headers: requestObj.headers,

        method: "post",
        data: {
          deleteFileKeys: [
            {
              fileGroupKey: FILE_GROUP_KEY,
              fileKey: FILE_KEY,
              aspCustKey: asp_cust_key,
              amdrId: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            },
          ],
        },
      })
        .then((res) => {
          rtnBln = true;
        })
        .catch((res) => {
          // status 200이 아닌경우에도 콜백호출 (프로젝트에 맞게 수정필요)
          rtnBln = false;
        });
      return rtnBln;
    },

    /**
     * 첨부파일 다중 삭제.
     * @param {List} dataList checkBox 체크된 데이터 배열.
     * @return {boolean}
     * @author
     */
    async deleteFilesMulti(dataList) {
      let rtnBln = false;
      let requestObj = {
        headers: {},
        data: {},
      };

      let delData = [];
      for (let i in dataList) {
        delData.push({
          fileGroupKey: dataList[i].FILE_GROUP_KEY,
          fileKey: dataList[i].FILE_KEY,
          aspCustKey: dataList[i].ASP_CUST_KEY,
          amdrId: this.$store.getters["userStore/GE_USER_ID"],
        });
      }

      const token = window.sessionStorage.getItem("token");
      // request.headers.token = token;
      //resuest header에 전달받은 accessToken을 Bearer 형식으로 전송
      requestObj.headers.Authorization = "bearer " + token;

      await axios({
        url: process.env.VUE_APP_API_URL + "api/file/delete",
        headers: requestObj.headers,
        method: "post",
        data: { deleteFileKeys: delData },
      })
        .then((res) => {
          rtnBln = true;
        })
        .catch((res) => {
          // status 200이 아닌경우에도 콜백호출 (프로젝트에 맞게 수정필요)
          rtnBln = false;
        });
      return rtnBln;
    },

    async mixin_getFileList(FILE_GROUP_KEY) {
      let rtn = [];
      let requestData = {
        headers: {},
        sendData: {},
      };
      // header 세팅
      requestData.headers["URL"] = "api/file/json/list";
      requestData.headers["SERVICE"] = "file.json";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["FILE_GROUP_KEY"] = FILE_GROUP_KEY;

      // 결과 리턴
      let response = await this.common_postCall(requestData, false);

      if (!response.HEADER.ERROR_FLAG) {
        rtn = response.DATA;
      }

      return rtn;
    },

    /*
      item (필수): 파일 목록 v-data-table item
      item 구조 : {
        FILE_KEY :파일키
        , SAVE_FILENAME : 저장된 파일명
        , ORIGINAL_FILENAME : 원본 파일명
        , FILE_SIZE : 파일 사이즈
        , FILE_EXTS : 파일 확장자
        , DNLOD_CNT : 다운로드 수
        , ASP_CUST_KEY : 회사구분
      }
      busiType (필수) : RepositoryBusiType.java 참조 (palette, phone, chat, km, bbs 해당 값만 허용됨)
      pathType (필수) : RepositoryPathType.java 참조 (publics, files, images, videos, inqrytypeimage 해당 값만 허용됨)
    */
    async mixin_fileDownload(item, busiType, pathType) {
      this.requestDownloadApi({
        url: "api/file/" + busiType + "/" + pathType + "/download", // required
        headers: {
          // required
          Authorization: "",
        },
        callback: (data) => {
          // required
          this.setFileDownCnt(
            item.FILE_GROUP_KEY,
            item.FILE_KEY,
            item.ASP_CUST_KEY
          );
          item.DNLOD_CNT = Number(item.DNLOD_CNT) + 1;
        },
        data: {
          // optional
          fileGroupKey: item.FILE_GROUP_KEY,
          aspNewCustKey: item.ASP_CUST_KEY,
          aspCustKey: item.ASP_CUST_KEY,
          procId: this.$store.getters["userStore/GE_USER_ROLE"].userId,
          fileKey: item.FILE_KEY,
          uploadUri: "/api/file/" + busiType + "/" + pathType + "/uploads",
          downloadUri: "/api/file/" + busiType + "/" + pathType + "/download",
          fileUploadLib: "dropzone",
          dir:
            "/PALETTE2/hkcloud/project/deploy/production/repository/web/" +
            busiType +
            "/" +
            pathType,
          tempDir:
            "/PALETTE2/hkcloud/project/deploy/production/repository/web/" +
            busiType +
            "/" +
            pathType +
            "/temp",
          targetType: "FILE",
          acceptedFiles: "image/*,*.doc,*.docx,*.pdf,*.csv,*.xls,*.xlsx,*.ppt",
        },
      });
    },

    async mixin_fileDownloadHlw(item) {
      this.requestDownloadApi({
        url: "api/file/download/"+item.REL_UPLD_ID, // required
        headers: {
          // required
          Authorization: "",
        },
        callback: (data) => {
          // required
          // this.setFileDownCnt(
          //   item.UPLD_GROUP_ID,
          //   item.UPLD_ID,
          // );
          console.log(data)
        },
        data: {
          // optional
          fileGroupKey: item.FILE_GROUP_KEY,
          procId: this.$store.getters["userStore/GE_USER_ROLE"].userId,
          fileKey: item.UPLD_ID,
          uploadUri: "/api/file/download/{upldId}",
          downloadUri: "/api/file/download/{upldId}",
          targetType: "FILE",
          acceptedFiles: "image/*,*.doc,*.docx,*.pdf,*.csv,*.xls,*.xlsx,*.ppt",
        },
      });
    },

    mixin_getFileSize(fileSize) {
      let rtnStr = "-";
      let divSize = 1024;
      let tempSize = fileSize;
      if (fileSize != undefined && fileSize != "" && fileSize > 0) {
        if (tempSize > divSize) {
          tempSize = (Number(tempSize) / 1024).toFixed(0);
          if (tempSize > divSize) {
            tempSize = (Number(tempSize) / 1024).toFixed(0);
            rtnStr = tempSize + "Mb";
          } else {
            rtnStr = tempSize + "Kb";
          }
        } else {
          rtnStr = "1Kb";
        }
      } else {
        return rtnStr;
      }

      return rtnStr;
    },


    /**
     * 챗관련 추가 Start_Arthur.Kim
     */
     
    /**
     * 바이트 기준으로 문자열 길이를 계산하여 반환한다.
     * @param agStr : 문자열
     * @returns 문자열의 길이
     * @author MPC R&D Team  
     */
    getByte(agStr){

      let tmpStr;
      let temp=0;
      let onechar;
      let tcount;
      
      let pCnt = 1;
      let k = 0;

      tcount = 0;
      tmpStr = new String(agStr);
      
      temp = tmpStr.length;
      
      for (; k < temp; k++ ){
          onechar = tmpStr.charAt(k);
          pCnt =1;
          
          if ( escape(onechar).length > 4 ){
              pCnt = 2;
          }

          tcount = tcount+pCnt;
      }
      return tcount;
    },

    /**
     * 바이트 기준으로 문자열 자른다.
     * @param agStr : 문자열
     * @returns 문자열의 길이
     * @author MPC R&D Team  
     */
    cutByteLength(s, len) {

      let size = 0;
      let rIndex = 0;
      let i = 0;

      if (s == null || s.length == 0) {
          return 0;
      }

      rIndex = s.length;
      
      for (; i < s.length; i++) {

          size += this.charByteSize(s.charAt(i));

          if( size == len ) {
              rIndex = i + 1;
              break;
          } else if( size > len ) {
              rIndex = i;
              break;
          }
      }

      return s.substring(0, rIndex);
    },
  
    charByteSize(ch) {

      if (ch == null || ch.length == 0) {
          return 0;
      }

      let charCode = ch.charCodeAt(0);

      if (charCode <= 0x00007F) {
          return 1;
      } else if (charCode <= 0x0007FF) {
          return 2;
      } else if (charCode <= 0x00FFFF) {
          return 3;
      } else {
          return 4;
      }
    },

    /**
     * 문자열에서 특정문자열을 찾아서 특정문자로 변환한다.(정규식 사용하지 않고 직접처리함)
     * @param {String} strText 전체 문자열
     * @param {String} strFind 찾을 문자열
     * @param {String} strChange 변경할 문자열
     * @return {String} 치환된 문자열
     */
    replaceAll(strText, strFind, strChange){

      if(typeof(strText)		=== "undefined"){strText	= "";}
      if(typeof(strFind)		=== "undefined"){strFind	= "";}
      if(typeof(strChange)	=== "undefined"){strChange	= "";}
      if(typeof(strText)		!== "string"){strText	= strText.toString();}
      if(typeof(strFind)		!== "string"){strFind	= strFind.toString();}
      if(typeof(strChange)	!== "string"){strChange	= strChange.toString();}

      return strText.split(strFind).join(strChange);
    }, 

    /**
     * XSS이 적용된 문자를 원래문자로 변환한다.
     * @param {String} strText 전체 문자열
     */
    restoreXSS(strText) {

      //값존재유무 체크
      if(strText === "" || strText == null){
        return strText;
      }
      
      strText = strText.toString();
      // strText = JSON.parse(strText);

      //문자열 길이가 4이상일 경우만 처리
      //  if(strText.length <= 3){return strText;}

      //XSS이 적용되었을 경우 원래 문자로 치환하여 반환한다.
      strText = this.replaceAll(strText,"&amp;","&");
      strText = this.replaceAll(strText,"&amp;amp","&");
      strText = this.replaceAll(strText,"&lt;","<");
      strText = this.replaceAll(strText,"&gt;",">");
      strText = this.replaceAll(strText,"&#40;","(");
      strText = this.replaceAll(strText,"&#41;",")");
      strText = this.replaceAll(strText,"&apos;","'");
      strText = this.replaceAll(strText,"&#91;","[");
      strText = this.replaceAll(strText,"&#93;","]");
      strText = this.replaceAll(strText,"&quot;",'"');

      return strText;
   },
  
   /**
    * 
    * @param {*} strText 
    * @returns 
    */
    restoreXSS_Dec(strText) {

      //값존재유무 체크
      if(strText === "" || strText == null) { 
        return strText;
      }

      strText = strText.toString();

      //문자열 길이가 4이상일 경우만 처리 
      // 이부분이 왜 걸려잇는지 모르겠음...테스트 후 길이체크는 막을 예정..
      // if(strText.length <= 3){return strText;}

      //XSS이 적용되었을 경우 원래 문자로 치환하여 반환한다.
      strText = this.replaceAll(strText,"<","&lt;");
      strText = this.replaceAll(strText,">","&gt;");
      strText = this.replaceAll(strText,"(","&#40;");
      strText = this.replaceAll(strText,")","&#41;");
      strText = this.replaceAll(strText,"[","&#91;");
      strText = this.replaceAll(strText,"]","&#93;");
      //strText = replaceAll(strText,"'","&apos;");
      //strText = replaceAll(strText,"&","&amp;");

      return strText;
   },
   
    /**
     * 챗관련 추가 End_Arthur.Kim
     */
    //calendar 포맷 정의(일자)
    mixin_getDate(date) {
      const day = date.split("-")[2];
      const day_num = Number(day);
      return day_num;
    },
    //전화번호 포맷 변경이력일련번호
    //11자리 XXX-XXXX-XXXX
    //10자리 02-XXXX-XXXX 또는 XXX-XXX-XXXX
    mixin_setPhoneNo(no) {

      if (!no) { return ""; }
      
      let setNum = "";

      if (no.length === 11) {
        setNum = no.substring(0, 3) + "-" + no.substring(3, 7) + "-" + no.substring(7, 11);
      } else if (no.length === 10) {
        if (no.substring(0, 2) === "02") {
          setNum = no.substring(0, 2) + "-" + no.substring(2, 6) + "-" + no.substring(6, 10);
        } else {
          setNum = no.substring(0, 3) + "-" + no.substring(3, 6) + "-" + no.substring(6, 10);
        } 
      } else if (no.length === 9) {
        setNum = no.substring(0, 2) + "-" + no.substring(2, 5) + "-" + no.substring(5, 9);    
      } else if (no.length === 8) {
        setNum = no.substring(0, 4) + "-" + no.substring(4, 8);    
      } else {
        setNum = no;
      }

      return setNum;
    },

    //사용자 버튼 사용 여부 확인
    mixin_set_btn(menu_id, button_id) {
      //console.log("menu_id", menu_id);
      //console.log("button_id", button_id);
      if (!menu_id || !button_id) return false;
      let return_data = false;
      let set_id = menu_id.replace("MENU_", "");
      let menu_list = this.$store.getters["userStore/GE_USER_MENU_LIST"];

      for (let i = 0; i < menu_list.length; i++) {
        if (menu_list[i].C_NODE_NO === set_id) {
          if (menu_list[i].BTN_AUTH) {
            let btn_list = menu_list[i].BTN_AUTH.split(",");
            if (btn_list.indexOf(button_id) > -1) {
              return_data = true;
            }
          }
        }
      }

      return return_data;
    },

    // null 체크
    checkValueNull(num) {
    
      if(typeof num == undefined || num == null || num == "") {
        return true;
      } else {
        return false;
      }
    },

    //마스킹 처리
    mixin_mask_num(num) {
      
      let preMaskingData = num;
      let MaskingData = "";
      let len = "";

      let emailMatchValue = preMaskingData.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);

      if(this.checkValueNull(emailMatchValue) == true) {
          MaskingData = preMaskingData;
      } else {
          len = emailMatchValue.toString().split('@').length;
          MaskingData = preMaskingData.toString().replace(new RegExp('.(?=.{0,' + len + '}@)', 'gi'), '*');
      }

      let phoneMatchValue = MaskingData.match(/\d{2,3}-\d{3,4}-\d{4}/gi);
      let phoneMatchValue2 = MaskingData.match(/\d{4}-\d{4}/gi);

      if(/-[0-9]{3}-/.test(phoneMatchValue)) {
          MaskingData= MaskingData.toString().replace(phoneMatchValue, phoneMatchValue.toString().replace(/-[0-9]{3}-/g, "-***-"));
      } else if(/-[0-9]{4}-/.test(phoneMatchValue)) {
          MaskingData= MaskingData.toString().replace(phoneMatchValue, phoneMatchValue.toString().replace(/-[0-9]{4}-/g, "-****-"));
      }

      if (num.length == 8) {
        if(/-[0-9]{4}/.test(phoneMatchValue2)) {
          MaskingData= MaskingData.toString().replace(phoneMatchValue2, phoneMatchValue2.toString().replace(/-[0-9]{4}/g, "-****"));
        }
      }

      
      //phoneMatchValue = MaskingData.length < 11 ? MaskingData.match(/\d{10}/gi) : MaskingData.match(/\d{11}/gi);
      
      if (MaskingData.length < 9) {
        phoneMatchValue2 = MaskingData.match(/\d{8}/gi)
      }else if (MaskingData.length < 10) {
        phoneMatchValue = MaskingData.match(/\d{9}/gi)
      } else if (MaskingData.length < 11) {
        phoneMatchValue = MaskingData.match(/\d{10}/gi)
      } else {
        phoneMatchValue = MaskingData.match(/\d{11}/gi)
      }

      //console.log("MaskingData : " + MaskingData); 
      // console.log("phoneMatchValue : " + phoneMatchValue); 
      // console.log("MaskingData : " + MaskingData);   
      // console.log("phoneMatchValue : " + phoneMatchValue);  


      if(this.checkValueNull(phoneMatchValue) != false && this.checkValueNull(phoneMatchValue2) != false ) {
          MaskingData = MaskingData;
      } else {
        if(MaskingData.length < 9) {
          MaskingData = MaskingData.toString().replace(phoneMatchValue2, phoneMatchValue2.toString().replace(/(\d{4})(\d{4})/gi,'$1****'));
        } else if(MaskingData.length < 10) {
          MaskingData = MaskingData.toString().replace(phoneMatchValue, phoneMatchValue.toString().replace(/(\d{2})(\d{3})(\d{4})/gi,'$1***$3'));
        } else if(MaskingData.length < 11) {

          let areaCd = preMaskingData.substring(0, 2);
           
          if (areaCd == '02') {
            MaskingData = MaskingData.toString().replace(phoneMatchValue, phoneMatchValue.toString().replace(/(\d{2})(\d{4})(\d{4})/gi,'$1***$3'));
          } else {
            MaskingData = MaskingData.toString().replace(phoneMatchValue, phoneMatchValue.toString().replace(/(\d{3})(\d{3})(\d{4})/gi,'$1***$3'));
          }
         
        } else {
          MaskingData = MaskingData.toString().replace(phoneMatchValue, phoneMatchValue.toString().replace(/(\d{3})(\d{4})(\d{4})/gi,'$1****$3'));
        }
      }

      return MaskingData;
    },

    //사용자별 소속 조회
    mixin_get_blng(asp_cust_key){
      let blngObj = {}
      api.post("/api/chat/dashboard/ATTR/inqire", {
        ASP_NEWCUST_KEY: asp_cust_key,
        ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
        ASP_CUST_KEY: asp_cust_key,
        USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
      },
      { 
        head: {
          SERVICE: "chat.dashboard.ATTR",
          METHOD: "inqire",
          TYPE: "BIZ_SERVICE",
        } 
      })
      .then((ret) => {
        //console.log("소속정보", ret.data.DATA);
        blngObj = ret.data.DATA;
      });

      return blngObj;
    },

    //회사별 공통코드 조회
    mixin_common_code_byCust(all_code, group_cd, strHeadText, aspCustKey){
      var responseData = [];

      if (strHeadText != "" && strHeadText != undefined) {
        responseData.push({ text: strHeadText, value: "" });
      }

      if (all_code && typeof all_code === "object") {
        for (var i = 0; i < all_code.length; i++) {
          if (all_code[i].group_cd === group_cd) {
            for (var a = 0; a < all_code[i].group_value.length; a++) {
              if(all_code[i].group_value[a].ETC_INFO01 == '' || all_code[i].group_value[a].ETC_INFO01 == null){
                //기타코드1 비어있을때
                if(all_code[i].group_value[a].ETC_INFO02 == '' || all_code[i].group_value[a].ETC_INFO02 == null){
                  //기타코드1 비어있고 기타코드2 비어있을때
                  responseData.push({
                    text: all_code[i].group_value[a].CD_NM,
                    value: all_code[i].group_value[a].CD,
                    info1: all_code[i].group_value[a].ETC_INFO01,
                    info2: all_code[i].group_value[a].ETC_INFO02,
                    info3: all_code[i].group_value[a].ETC_INFO03,
                  });
                } else {
                  //기타코드1 비어있고 기타코드2 존재할때
                  let chkAspCus = all_code[i].group_value[a].ETC_INFO02.split(',');
                  let chkAspCusLeng = chkAspCus.length;
                  let n = 0;
                  let cnt = 0;
                  for(n;n<chkAspCusLeng;n++){
                    if(chkAspCus[n] == aspCustKey){
                      cnt++;
                    }
                  }
                  if(cnt == 0){
                    responseData.push({
                      text: all_code[i].group_value[a].CD_NM,
                      value: all_code[i].group_value[a].CD,
                      info1: all_code[i].group_value[a].ETC_INFO01,
                      info2: all_code[i].group_value[a].ETC_INFO02,
                      info3: all_code[i].group_value[a].ETC_INFO03,
                    });
                  }
                }
              } else {
                //기타코드1 존재할때 >> 기타코드2는 조회할 필요 없음
                let chkAspCus = all_code[i].group_value[a].ETC_INFO01.split(',');
                let chkAspCusLeng = chkAspCus.length;
                let n = 0;
                for(n;n<chkAspCusLeng;n++){
                  if(chkAspCus[n] == aspCustKey){
                    responseData.push({
                      text: all_code[i].group_value[a].CD_NM,
                      value: all_code[i].group_value[a].CD,
                      info1: all_code[i].group_value[a].ETC_INFO01,
                      info2: all_code[i].group_value[a].ETC_INFO02,
                      info3: all_code[i].group_value[a].ETC_INFO03,
                    });
                  }
                }
              }
              
            }
          }
        }
      }
      //console.log("responseData", responseData);
      return responseData;
    },

    //상위레벨 선택
    mixin_getLVCode(all_code, group_cd, strHeadText, CHK, LV2){
      var responseData = [];

      if (strHeadText != "" && strHeadText != undefined) {
        responseData.push({ text: strHeadText, value: "" });
      }
      if (all_code && typeof all_code === "object") {
        for (var i = 0; i < all_code.length; i++) {
          if (all_code[i].group_cd === group_cd) {
            for (var a = 0; a < all_code[i].group_value.length; a++) {
              if(all_code[i].group_value[a].ETC_INFO01 == CHK){
                if(CHK == '소분류'){
                  if(all_code[i].group_value[a].CD.includes(LV2)){
                    responseData.push({
                      text: all_code[i].group_value[a].CD_NM,
                      value: all_code[i].group_value[a].CD,
                      info1: all_code[i].group_value[a].ETC_INFO01,
                      info2: all_code[i].group_value[a].ETC_INFO02,
                      info3: all_code[i].group_value[a].ETC_INFO03,
                    });
                  }
                } else { 
                  responseData.push({
                    text: all_code[i].group_value[a].CD_NM,
                    value: all_code[i].group_value[a].CD,
                    info1: all_code[i].group_value[a].ETC_INFO01,
                    info2: all_code[i].group_value[a].ETC_INFO02,
                    info3: all_code[i].group_value[a].ETC_INFO03,
                  });
                }
              }
            }
          }
        }
      }
      //console.log("responseData", responseData);
      return responseData;
    },

    mixin_check_AspCustKey_number(){
      let num = this.$store.getters["userStore/GE_USER_ROLE"].company.length
      if(num == 1){
        return "Y"
      } else {
        return "N"
      }
    },

    //최상위 공통코드 가져오기
    mixin_get_first_cmm(info, AspCustKey){
      let reData = [];
      api.post("/api/palette/common/get-cmm/list", {
        INFO1: info,
        ASP_NEWCUST_KEY: AspCustKey,
        ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
        ASP_CUST_KEY: AspCustKey,
        USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
      },
      { 
        head: {
          SERVICE: "palette.common.get-cmm",
          METHOD: "list",
          TYPE: "BIZ_SERVICE",
        } 
      })
      .then((ret) => {
        //console.log("최상위 코드", ret.data.DATA);
        reData = ret.data.DATA;
      });
      return reData;
    },

    //회사코드로 회사명 가져오기
    mixin_getCompanyNM(AspCustKey){
      let reData = '';
      api.post("/api/palette/common/getCompanyNM/inqire", {
        ASP_NEWCUST_KEY: AspCustKey,
        ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
        ASP_CUST_KEY: AspCustKey,
        USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
      },
      { 
        head: {
          SERVICE: "palette.common.getCompanyNM",
          METHOD: "inqire",
          TYPE: "BIZ_SERVICE",
        } 
      })
      .then((ret) => {
        //console.log("최상위 코드", ret.data.DATA);
        reData = ret.data.DATA[0].COMPANY_NM;
      });
      return reData;
    },

    mixin_getToken(){
      return "bearer "+window.sessionStorage.getItem("token")
    },

    mixin_resize(event) {
      event.target.style.height='auto'
      event.target.style.height='${event.target.scrollHeight}px'
    },

    async mixin_survey_get(param) {
      var responseData = [];
      await api
        .post(
          "api/hlw/campaign/manage/survey/list",
          {
            CTCP_CODE:param.CTCP_CODE,
            BUSS_CODE:param.BUSS_CODE,
            CMP_SURVEY_NAME:'',
            USE_YN:param.USE_YN,
            PART_TYPE:param.PART_TYPE,
          },
          {
            head: {
              METHOD: "inqry",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          if (response.data) {
            responseData = response.data["DATA"];
          }
        })
        .catch((error) => {});
      return responseData;
    },

    mixin_sp_replace(e) {
      let obj=e.currentTarget
      var regExp = /[\{\}\[\]\/.,:|\)~`^\-_+┼<>\#$%&\'\"\\\(\=]/gi; 
      if( regExp.test(obj.value) ){
        obj.value=obj.value.replaceAll(regExp, '')
      }
      return obj.value
    },

    mixin_decode(strText) {

      //값존재유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }
      strText = strText.toString();
    
      //문자열 길이가 4이상일 경우만 처리
      if (strText.length <= 3) {
        return strText;
      }
      //순서바뀌면 안나옴
      strText = strText.replaceAll("&lt;", "<");
      strText = strText.replaceAll("&gt;", ">");
      strText = strText.replaceAll("&amp;", "&");
      strText = strText.replaceAll("&quot;", '"');
      strText = strText.replaceAll("&apos;", "'");
      strText = strText.replaceAll("<br>", "\r");
      strText = strText.replaceAll("</br>", "\r");
      strText = strText.replaceAll("<br/>", "\r");
      strText = strText.replaceAll("<p>", "\n");
      strText = strText.replaceAll("&#91;", "[");
      strText = strText.replaceAll("&#93;", "]");
      strText = strText.replaceAll("&#40;", "(");
      strText = strText.replaceAll("&#41;", ")");
    
      return strText;
    },

    mixin_encode(strText) {

      if (strText === "" || strText == null) {
        return strText;
      }

      strText = strText.replaceAll("(", "&#40;");
      strText = strText.replaceAll(")", "&#41;");
      strText = strText.replaceAll("[", "&#91;");
      strText = strText.replaceAll("]", "&#93;");
      strText = strText.replaceAll("&", "&amp;");
      strText = strText.replaceAll('"', "&quot;");
      strText = strText.replaceAll("'", "&apos;");
      strText = strText.replaceAll("\r", "<br>");
      strText = strText.replaceAll("\n", "<p>");
      strText = strText.replaceAll("<", "&lt;");
      strText = strText.replaceAll(">", "&gt;");

      return strText;
    },

    mixin_pwdChk(pwNew) {
      let num = /[0-9]/g;
      let eng = /[a-z]/gi;
      let spe = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g;

      if (!num.test(pwNew)) {
        return false;
      } else if (!eng.test(pwNew)) {
        return false;
      } else if (!spe.test(pwNew)) {
        return false;
      } else if (this.mixin_isEmpty(pwNew)) {
        return false;
      } else if (pwNew.length < 4 || pwNew.length > 20) {
        return false;
      } else if (pwNew.search(/\s/) != -1) {
        return false;
      }

      return true;
    },

    //모바일 여부
    mixin_mobi_yn() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      }
      return false;
    },

    mixin_valid_url(url) {
      const RegExp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
      if(!this.mixin_isEmpty(url) && RegExp.test(url)) {
        return true;
      } else {
        return false;
      }
    },

  },

};
